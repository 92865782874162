import {useEffect, useState} from 'react';
import Skelet from "../../../elements/Skelet";
import {useGetGoalsUser} from "../../../hook/useGetGoals";
import {useNavigate} from "react-router-dom";


const GoalUser = () => {
    const [user, setUser] = useState('all')
    const {data: goaluser, isLoading, isError} = useGetGoalsUser(2024,12,user)
    const [data, setData] = useState()
    const navigate = useNavigate()

    useEffect(()=>{
        if (goaluser){
            let x = goaluser.data.response.data
            let keysSorted = x.sort(function(a,b) {
                let x = a.name.toLowerCase();
                let y = b.name.toLowerCase();
                return x > y ? -1 : x > y ? 1 : 0;
            });
            setData(keysSorted)
        }
    },[goaluser])

    const select = (uId) =>{
        navigate(`/goalUser/${uId}`)
        //console.log(uId)
    }


    if (isLoading) {return <Skelet option='ecoData'/>}
    if (isError) {return <h3>Нет подключения к серверу</h3>}
    if (!goaluser) {return <h3>Нет данных с сервера</h3>}

    return (
        <div>
            <div style={{textAlign: 'center'}}>Доступные данные по KPI</div>
            <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '30px'}}>
                {
                    data && data.map((item,i) =>{
                        return (
                            <div onClick={()=>select(item.id)} key={i} style={{padding: '15px', textAlign: 'center', flexBasis: '170px', border: '1px solid #818181', borderRadius: '10px', marginBottom: '10px', cursor: 'pointer'}}>
                                <div>{item.name}</div>
                                <div>код: {item.id}</div>
                            </div>
                        )
                    })
                }

            </div>
            {/*<GoalMatrix data={data}/>*/}
        </div>
    );
};

export default GoalUser;