import rosneft from '../../../../img/holding/rosneft.png'
import lukoil from '../../../../img/holding/lukoil.png'
import fosagro from '../../../../img/holding/fosagro.png'
import sibur from '../../../../img/holding/sibur.png'
import novatek from '../../../../img/holding/novatek.png'
import uralkaliy from '../../../../img/holding/uralkaliy.png'
import metafraks from '../../../../img/holding/metafraks.png'
import nologo from '../../../../img/holding/nologo.png'

export const holdingSelectImg = (holdName) =>{
    switch (holdName) {
        case ('РОСНЕФТЬ'):
            return  rosneft
        case ('НОВАТЭК'):
            return  novatek
        case ('УРАЛКАЛИЙ'):
            return  uralkaliy
        case ('МЕТАФРАКС'):
            return  metafraks
        case ('ФОСАГРО'):
            return  fosagro
        case ('ЛУКОЙЛ'):
            return  lukoil
        case ('СИБУР'):
            return  sibur
        default:
            return nologo
    }


}