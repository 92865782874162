import React from 'react';
import {useTheme} from "../../../../../hook/useTheme";

const ModalEcoBulletWiki = () => {
    const neonGreen = useTheme('neonGreen')

    return (
        <div className='wikiBlock'>
            <h2 className='wikiTitle' style={{color: neonGreen}}>Издержки</h2>
            <p>
                График <b style={{color: neonGreen}}>"Издержки"</b> отражает операционные затраты текущего периода с накоплением.
            </p>
            <p><b>Данные формируются на основе оборотов уровней 0, 1 и 2 по следующим статьям:</b></p>
            <ul>
                <li>А30: Общепроизводственные, штрафы и удержания по техническому обслуживанию (ТО)</li>
                <li>А40: Ресурсные (услуги и финрез)</li>
                <li>А50: Обеспечивающие (услуги и финрез)</li>
                <li>А52: Накладные</li>
                <li>А54: Налоги</li>
                <li>А60: АМГ Бизнес (фонд дохода ГИПа, РС и АМГ)</li>
                <li>А62 АМГ Управление</li>
                <li>А63: Фонд издержек (% альтернативных издержек, КП проигранные)</li>
            </ul>
            <p><b>Особенности графика:</b></p>
            <ul>
                <li>Учитываются только текущие затраты с накоплением за предыдущие периоды за минусом СС</li>
                <li>График позволяет оперативно анализировать структуру издержек и контролировать их уровень в текущем периоде</li>
            </ul>
        </div>
    );
};

export default ModalEcoBulletWiki;