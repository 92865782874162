import React from 'react';
import {useTheme} from "../../../../../hook/useTheme";

const ModalEcoBarChartWiki = () => {
    const neonGreen = useTheme('neonGreen')

    return (
        <div className='wikiBlock'>
            <h2 className='wikiTitle' style={{color: neonGreen}}>Годовой «Столбчатая гистограмма»</h2>
            <p>
                На <b style={{color: neonGreen}}>Гистограммном графике</b> представлены данные в разрезе изменений за месяц, разделенные на две группы:
            </p>
            <ul style={{listStyle: 'square'}}>
                <li>План/Факт – по прошедшим и текущим месяцам</li>
                <li>План/Прогноз – по будущим месяцам</li>
            </ul>
            <p>
                График отражает отклонения фактических и прогнозируемых значений от плановых показателей, за исключением операционных издержек,
                которые отображают отклонения от предыдущего месяца.
            </p>
            <p><b>Особенности графика:</b></p>
            <ul>
                <li>позволяет наглядно сравнить плановые, фактические и прогнозируемые данные</li>
                <li>выделяет отклонения, что помогает оценить выполнение планов и скорректировать стратегию</li>
                <li>операционные издержки представлены в контексте изменений относительно предыдущего месяца, что упрощает анализ их динамики</li>
                <li>график является полезным инструментом для контроля выполнения планов, анализа тенденций и принятия управленческих решений</li>
            </ul>
            <p>
                График является полезным инструментом для контроля выполнения планов, анализа тенденций и принятия управленческих решений.
            </p>
</div>
    );
};

export default ModalEcoBarChartWiki;