import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useGetGoalsUser} from "../../../../hook/useGetGoals";
import Skelet from "../../../../elements/Skelet";
import '../goaluser.scss'
import MiniLineChart from "./MiniLineChart";
import BlockShadow from "../../../../elements/BlockShadow";
import TableHead from "../../../../elements/Table/TableHead";
import Scroll from "../../../../elements/Scroll/Scroll";
import TableItem from "../../../../elements/Table/TableItem";
import {useNavigate} from "react-router";
import NeonButton from "../../../../elements/NeonButton/NeonButton";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";


const GoalUserMatrix = () => {
    let params = useParams()
    const navigate = useNavigate();
    const {data: goaluser, isLoading, isError} = useGetGoalsUser(2024,12,params.user)
    const [data, setData] = useState([])
    const goBack = ()=>{navigate('/goalUser')}

    useEffect(()=>{
        if (goaluser){
            let x = goaluser.data.response.data
            setData(x)
        }
    },[goaluser])

    if (isLoading) {return <Skelet option='ecoData'/>}
    if (isError) {return <h3>Нет подключения к серверу</h3>}
    if (!goaluser) {return <h3>Нет данных с сервера</h3>}
    if (goaluser.data.response.data.length === 0){return <h3>Нет данных с сервера</h3>}

    return (
        <div>
            <div style={{marginTop: '5px', marginLeft: '15px'}}>
                <NeonButton text={'Назад'} tooltip={'Вернуться назад'} func={goBack} startIcon={<ReplyAllIcon />}/>
            </div>
            {
                goaluser.data.response.data.roles.map((item,i)=>{
                    return <div key={i}>
                    <div className='headerGoal'>
                        <div>ФИО: {data.name}</div>
                        <div>Должность: {data.position}</div>
                        <div>Результативность текущая: {item.result}</div>
                        <div>ЦКП: {item.ckp}</div>
                        <div>Руководитель: {item.boss}</div>
                    </div>
                    <BlockShadow >
                        <TableHead extra={'tac fs13'}>
                            <div className='blue' style={{width: '3%', fontStyle: 'italic'}} >сфера</div>
                            <div className='red' style={{width: '3%', fontStyle: 'italic'}} >цель</div>

                            <div className='green' style={{width: '12%', fontStyle: 'italic'}} >РЕЗУЛЬТИРУЮЩИЕ</div>
                            <div className='orange' style={{width: '11%', fontStyle: 'italic'}} >ОПЕРЕЖАЮЩИЕ</div>

                            <div style={{width: '6%'}} >Вектор:</div>
                            <div style={{width: '6%'}} >Вес показателя</div>
                            <div style={{width: '10%'}} >РЕЗУЛЬТАТИВНОСТЬ</div>
                            <div style={{width: '7%'}} >СверхЦелевое</div>
                            <div style={{width: '7%'}} >Целевое</div>
                            <div style={{width: '7%'}} >Приемлемое</div>
                            <div style={{width: '6%'}} >Критическое</div>
                            <div style={{width: '10%'}} >Миниграфик (-6 мес)</div>
                            <div style={{width: '6%'}} >Тренд</div>
                            <div style={{width: '6%'}} >ФАКТ значение</div>
                        </TableHead>
                    </BlockShadow>
                    <Scroll h='h228' >
                        {
                            item.sfera.map((sf,idx)=>{
                                return <div key={idx}>
                                    <div className='gtitle blue'>{sf.sferaName}</div>
                                    {
                                        sf.goals.map((g, idx2)=>{
                                            return <div key={idx2}>
                                                <div className='gtitle red'>{g.goalName}</div>
                                                {
                                                    g.data.map((data, idx3)=>{
                                                        if(data.resultparam) {

                                                            return <GreenBlock text={data.dataName} key={idx3}>
                                                                <div className='tac' style={{width: '10%'}}>{data.vector}</div>
                                                                <div className='tac' style={{width: '10%'}}>{data.ves}</div>
                                                                <div className='tac' style={{width: '10%'}}>{data.result}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.sverhGoal}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.goal}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.good}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.critical}</div>
                                                                <div className='tac' style={{width: '10%'}}> <MiniLineChart/></div>
                                                                <div className='tac' style={{width: '10%'}}>{data.trend}</div>
                                                                <div className='tac' style={{width: '6%'}}>{new Intl.NumberFormat("ru").format(data.fact)}</div>
                                                            </GreenBlock>
                                                        } else {
                                                            return <OrangeBlock text={data.dataName} key={idx3}>
                                                                <div className='tac' style={{width: '10%'}}>{data.vector}</div>
                                                                <div className='tac' style={{width: '10%'}}>{data.ves}</div>
                                                                <div className='tac' style={{width: '10%'}}>{data.result}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.sverhGoal}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.goal}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.good}</div>
                                                                <div className='tac' style={{width: '6%'}}>{data.critical}</div>
                                                                <div className='tac' style={{width: '10%'}}> <MiniLineChart/></div>
                                                                <div className='tac' style={{width: '10%'}}>{data.trend}</div>
                                                                <div className='tac' style={{width: '6%'}}>{new Intl.NumberFormat("ru").format(data.fact)}</div>
                                                            </OrangeBlock>
                                                        }
                                                    })
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </Scroll>
                </div>
                })
            }
        </div>
    )
};

export default GoalUserMatrix;

const GreenBlock = ({text,extra, children}) => {

    return (
        <TableItem extra={'pad0'}>
            <div style={{width: '2%'}}></div>
            <div className='green' style={{width: '27%'}}>{text}</div>
            {children}
        </TableItem>
    )
}
const OrangeBlock = ({text,extra, children}) => {

    return (
        <TableItem extra={'pad0'}>
            <div style={{width: '3%'}} ></div>
            <div className='orange' style={{width: '26%'}}>{text}</div>
            {children}
        </TableItem>
    )
}