import Page404 from "../404/Page404";

const Resources = () => {
    return (
        <div>
            <Page404/>
        </div>
    );
};

export default Resources;