import React, {useState} from 'react';
import {useTheme} from "../../../../hook/useTheme";
import './wiki.scss'
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";

const ModalRealizProcWiki = () => {

    const [check, setCheck] = useState(false)
    /*const turnOff = () => {
        setCheck(!check)
        localStorage.setItem('wiki-Proc', JSON.stringify(true));

    }
*/
    const neonGreen = useTheme('neonGreen')

    return (
        <div className='wikiBlock'>
            <h2 className='wikiTitle' style={{color: neonGreen}}>Добро пожаловать на Панель Процентования!</h2>
            <p>
                Это <b style={{color: neonGreen}}>Панель Процентования</b>, на которой вы можете видеть блоки Объектов с групповыми гистограммами.
                Каждый блок имеет рамку, которая окрашивается в один из цветов светофора в зависимости от состояния текущих показателей по Объекту.
                При наведении на наименование объекта отобразится краткая справка с основными данными Объекта.
                При нажатии на кнопку со стрелочкой в правой и левой частях экрана, происходит перелистывание страниц с блоками Объектов.
            </p>
            <p>
                В верхней левой части Панели Процентования находятся <b style={{color: neonGreen}}>фильтры</b> по Холдингу, Заказчику и АМГ. Каждый из этих фильтров
                функционирует независимо, при этом сбрасывая настройки остальных фильтров
            </p>
            <p>
                В верхней правой части Панели Процентования расположен поиск по коду или наименованию Объекта, а также кнопка для сброса поиска и фильтров.
                Также в этой части вы можете видеть количество объектов, которые отображаются на страницах Панели Процентования с учётом применённых фильтров.
            </p>
            <p>
                В столбцах гистограммы отображается текущее значение показателя в процентах. Вертикальной чертой отделяется значение в 100 процентов.
                В скобках указана динамика показателя за прошедший месяц. При наведении на столбец гистограммы отображается полное наименование показателя.
                При клике на столбец гистограммы Объекта откроется справка, в которой отображается справочная информация по расчету и окрашиванию данного показателя гистограммы.
            </p>
            <p>
                При клике на кнопку <b style={{color: neonGreen}}>Крестик или на свободное место</b> произойдёт возврат на страницу со всеми Объектами.
            </p>
           {/* <FormControlLabel sx={{color: 'grey', mt: '15px'}} control={<Switch onClick={turnOff} checked={check} color="success"  size="small"/>} label="Не показывать справку по данному разделу" />*/}
        </div>
    );
};

export default ModalRealizProcWiki;