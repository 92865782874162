import React, {useEffect, useState} from 'react';
import '../GoalUser/goaluser.scss'
import {useGetGoalsUser} from "../../../hook/useGetGoals";
import Skelet from "../../../elements/Skelet";
import NeonButton from "../../../elements/NeonButton/NeonButton";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import BlockShadow from "../../../elements/BlockShadow";
import TableHead from "../../../elements/Table/TableHead";
import Scroll from "../../../elements/Scroll/Scroll";
import {GFormControl, GInputLabel} from "../../../elements/CustomMui/customMui";
import {useTheme} from "../../../hook/useTheme";
import {MenuItem, Select} from "@mui/material";
import MiniLineChart from "../GoalUser/subpages/MiniLineChart";
import TableItem from "../../../elements/Table/TableItem";
import CallMadeIcon from '@mui/icons-material/CallMade';
import RedoIcon from '@mui/icons-material/Redo';

const GoalObject = () => {
    const {data: goaluser, isLoading, isError} = useGetGoalsUser(2024,12, '000001492')
    const [data, setData] = useState([])
    const goBack = ()=>{
        //navigate('/goalUser')
        console.log('go back')
    }
    const text = useTheme('text')
    const select = useTheme('select')
    /*selected sfera*/
    const [sfera, setSfera] = useState(false)
    /*list sfera for select*/
    const [sferaList, setSferaList] = useState([])
    /*arr of sfera data*/
    const [sferaArr, setSferaArr] = useState([])
    /*sfera to display*/
    const [dispSfera, setDispSfera] = useState([])

    useEffect(()=>{
        if (goaluser){
            let x = goaluser.data.response.data
            setData(x)
            prepareSelect(x.roles[0].sfera)
        }
        if (sfera === false){
            setDispSfera(false)
        }else {
            const displ = sferaArr.find(i => i.sferaName === sfera)
            setDispSfera(displ)
        }
    },[goaluser, sfera, dispSfera, sferaArr])

    const prepareSelect = (arr) =>{
        let x = []
        arr.forEach(i => {
            if (!x.includes(i.sferaName)){
                x.push(i.sferaName)
            }
        } )
        setSferaArr(arr)
        return setSferaList(x.sort())
    }

    const handleChangeSfera = (e)=> {
        const item = e.target.value
        if(item === false) {
            setDispSfera([])
        } else {

        }
        setSfera(item)
    }

    const rostIcon = (name)=>{
        if (name === 'Рост'){
            return <CallMadeIcon sx={{verticalAlign: 'bottom', color: '#45a822'}}/>
        } else {
            return <RedoIcon sx={{verticalAlign: 'bottom', color: '#fd634e'}}/>
        }
    }

    if (isLoading) {return <Skelet option='ecoData'/>}
    if (isError) {return <h3>Нет подключения к серверу</h3>}
    if (!goaluser) {return <h3>Нет данных с сервера</h3>}
    if (data.length === 0){return <h3>Нет данных с сервера</h3>}

    return (
        <div>
            {/*<Dev/>*/}
            <div style={{display: 'flex',gap: '10px', marginTop: '5px', marginLeft: '15px'}}>
                <div style={{width: '250px'}}>
                    <NeonButton text={'Назад'} tooltip={'Вернуться назад'} func={goBack} startIcon={<ReplyAllIcon />}/>
                    <GFormControl sx={{width: 250,mt: '15px', borderBottom: '0.1rem solid #ffffff4a;'}} variant="standard">
                        <GInputLabel id="sfera" sx={{color: '#7bc4fe'}}>Сфера</GInputLabel>
                        <Select
                            labelId="sfera"
                            id="sferaSel"
                            value={sfera}
                            defaultValue='Все'
                            onChange={handleChangeSfera}
                            sx={{color: text}}
                            inputProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: select,
                                            color: text
                                        }
                                    }
                                }
                            }}
                        >
                            <MenuItem sx={{color: 'grey'}}  value={false}>Не выбрано</MenuItem>
                            {
                                sferaList.map((item, i)=>{
                                    return <MenuItem  key={i} value={item}>{item}</MenuItem>
                                })
                            }
                        </Select>
                    </GFormControl>
                </div>
                <div style={{width: '500px'}} className='goalTitles'>
                    <div><span>ФИО:</span>{data.name}</div>
                    <div><span>Должность:</span>{data.position}</div>
                    <div><span>Руководитель: {data.roles[0].boss}</span></div>
                </div>
                <div style={{flexGrow: '4'}} className='goalTitles'>
                    <div><span>Результат: </span> {data.roles[0].result}</div>
                    <div><span>ЦКП:</span>{data.roles[0].ckp}</div>
                </div>
            </div>

            <BlockShadow >
                <TableHead extra={'tac fs13'}>
                    <div className='blue' style={{width: '3%', fontStyle: 'italic'}} >сфера</div>
                    <div className='red' style={{width: '3%', fontStyle: 'italic'}} >цель</div>
                    <div className='green' style={{width: '12%', fontStyle: 'italic'}} >РЕЗУЛЬТИРУЮЩИЕ</div>
                    <div className='orange' style={{width: '11%', fontStyle: 'italic'}} >ОПЕРЕЖАЮЩИЕ</div>
                    <div style={{width: '6%'}} >Вектор:</div>
                    <div style={{width: '6%'}} >Вес показателя</div>
                    <div style={{width: '10%'}} >РЕЗУЛЬ-СТЬ</div>
                    <div style={{width: '7%'}} >СверхЦелевое</div>
                    <div style={{width: '7%'}} >Целевое</div>
                    <div style={{width: '7%'}} >Приемлемое</div>
                    <div style={{width: '6%'}} >Критическое</div>
                    <div style={{width: '10%'}} >Миниграфик (-6 мес)</div>
                    <div style={{width: '6%'}} >Тренд</div>
                    <div style={{width: '6%'}} >ФАКТ значение</div>
                </TableHead>
            </BlockShadow>
            <Scroll h='h228' >
                {
                    typeof dispSfera === 'object' &&
                        dispSfera.goals.map((g, i) =>{
                            return <div key={i}>
                                <div className='gtitle red'>{g.goalName}</div>
                                {
                                    g.data.map((gdata, idx3)=>{
                                        if(gdata.resultparam) {
                                            return <GreenBlock text={gdata.dataName} key={idx3}>
                                                <div className='tac' style={{width: '6%'}}>{rostIcon(gdata.vektor)}</div>
                                                <div className='tac' style={{width: '6%'}}>{gdata.ves}</div>
                                                <div className='tac' style={{width: '10%'}}>{gdata.result}</div>
                                                <div className='tac' style={{width: '7%'}}>{gdata.sverhGoal}</div>
                                                <div className='tac' style={{width: '7%'}}>{gdata.goall}</div>
                                                <div className='tac' style={{width: '7%'}}>{gdata.good}</div>
                                                <div className='tac' style={{width: '6%'}}>{gdata.critical}</div>
                                                <div className='tac' style={{width: '10%'}}> <MiniLineChart data={gdata.miniChart}/></div>
                                                <div className='tac' style={{width: '6%'}}>{gdata.trend}</div>
                                                <div className='tac' style={{width: '6%'}}>{new Intl.NumberFormat("ru").format(gdata.fact)}</div>
                                            </GreenBlock>
                                        } else {
                                            return <OrangeBlock text={gdata.dataName} key={idx3}>
                                                <div className='tac' style={{width: '6%'}}>{rostIcon(gdata.vektor)}</div>
                                                <div className='tac' style={{width: '6%'}}>{gdata.ves}</div>
                                                <div className='tac' style={{width: '10%'}}>{gdata.result}</div>
                                                <div className='tac' style={{width: '7%'}}>{gdata.sverhGoal}</div>
                                                <div className='tac' style={{width: '7%'}}>{gdata.goall}</div>
                                                <div className='tac' style={{width: '7%'}}>{gdata.good}</div>
                                                <div className='tac' style={{width: '6%'}}>{gdata.critical}</div>
                                                <div className='tac' style={{width: '10%'}}> <MiniLineChart/></div>
                                                <div className='tac' style={{width: '6%'}}>{gdata.trend}</div>
                                                <div className='tac' style={{width: '6%'}}>{new Intl.NumberFormat("ru").format(gdata.fact)}</div>
                                            </OrangeBlock>
                                        }
                                    })
                                }
                            </div>
                    })
                }
            </Scroll>
        </div>
    );
};

export default GoalObject;

const GreenBlock = ({text,children}) => {

    return (
        <TableItem extra={'pad0'}>
            <div style={{width: '2%'}}></div>
            <div className='green' style={{width: '27%'}}>{text}</div>
            {children}
        </TableItem>
    )
}
const OrangeBlock = ({text,children}) => {

    return (
        <TableItem extra={'pad0'}>
            <div style={{width: '3%'}}></div>
            <div className='orange' style={{width: '26%'}}>{text}</div>
            {children}
        </TableItem>
    )
}