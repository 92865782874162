import Scroll from "../../elements/Scroll/Scroll";
import './versionLog.scss'
import VersionItem from "./subpages/VersionItem";

const VersionLog = () => {
    const data = [
        {
            date: '20.02.2025',
            ver: '1.0.18',
            list: [
                {
                    name: 'Процентование',
                    li: ['Расширена область поиска +Контрагент/АМГ/РП', 'Изменен переключатель Месяц-Неделя', 'Обновлены справки'
                    ]
                },
                {
                    name: 'Цели и показатели',
                    li: ['Новый шаблон KPI пользователя',
                    ]
                },
                {
                    name: 'Экономика',
                    li: ['Обновлена справка', 'Изменены меню графиков'
                    ]
                },
            ]
        },
        {
            date: '31.01.2025',
            ver: '1.0.17',
            list: [
                {
                    name: 'Процентование',
                    li: ['Обновлены фильтры Заказчик/Холдинг/Амг', 'Изменена логика работы фильтров'
                    ]
                },
                {
                    name: 'Цели и показатели',
                    li: ['Добавлен просмотр детализации',
                    ]
                },
            ]
        },
        {
            date: '27.01.2025',
            ver: '1.0.16',
            list: [
                {
                    name: 'Авторизация',
                    li: ['Обновлена авторизация', 'Получение данных модулей по токену'
                    ]
                },
            ]
        },
        {
            date: '28.12.2024',
            ver: '1.0.15',
            list: [
                {
                    name: 'Персонал',
                    li: ['Обновлены макеты',
                    ]
                },
                {
                    name: 'Цели и показатели',
                    li: ['Добавлено 2 раздела показетелей по сотруднику и обьекту', "Выведены предаварительные данные по сотруднику"
                    ]
                },
            ]
        },
        {
            date: '20.12.2024',
            ver: '1.0.14',
            list: [
                {
                    name: 'Общий',
                    li: ['Новые иконки и разделы',
                    ]
                },
                {
                    name: 'Персонал',
                    li: ['Добавлены разделы - Кадры, Уволенные, Портрет сотрудника',
                    ]
                },
                {
                    name: 'Экономика',
                    li: ['Добавлены разделы справки для каждого элемента',
                    ]
                },
                {
                    name: 'Процентование',
                    li: ['Добавлен общий раздел справки',
                    ]
                },
            ]
        },
        {
            date: '03.12.2024',
            ver: '1.0.13',
            list: [
                {
                    name: 'Экономика',
                    li: ['Добавлена сортировка данных в таблице детализации воронки',
                    ]
                },
            ]
        },
        {
            date: '03.12.2024',
            ver: '1.0.12',
            list: [
                {
                    name: 'Экономика',
                    li: ['Исправлен показ данных в зависимости от просмотра - компания / РП', 'Обновлена загрузка данных',
                    ]
                },
            ]
        },
        {
            date: '02.12.2024',
            ver: '1.0.11',
            list: [
                {
                    name: 'Экономика',
                    li: ['Отображены данные детализации воронки', 'Исправлен формат дат и сумм',
                    ]
                },
            ]
        },
        {
            date: '25.11.2024',
            ver: '1.0.10',
            list: [
                {
                    name: 'Экономика',
                    li: ['Добавлено окно детализации воронки', 'Обновлена индикация загрузки данных',
                    ]
                },
            ]
        },
        {
            date: '22.11.2024',
            ver: '1.0.9',
            list: [
                {
                    name: 'Экономика',
                    li: ['Обновлены данные графиков', 'Изменен столбчатый график (наложение факта на план для более точной визуализации данных )',
                        "Изменены названия графиков"
                    ]
                },
                {
                    name: 'Финансы',
                    li: ['Добавлена диаграма санкей', 'Данные фейковые, только для визуализации',
                        "Изменены названия графиков"
                    ]
                },
            ]
        },
        {
            date: '15.11.2024',
            ver: '1.0.8',
            list: [
                {
                    name: 'Процентование',
                    li: ['Изменена библиотека графиков', 'Отключены фильтры по Заказчику, по цветам', 'Показ справок перенесен на клик по названиям параметра',
                        "Возможность изменить тип графика через контекстное меню каждого гарфика"
                    ]
                },
            ]
        },
        {
            date: '13.11.2024',
            ver: '1.0.7',
            list: [
                {
                    name: 'Экономика',
                    li: ['Обновлена светлая тема', 'Добавлена возможность изминения параметров данных', 'Показ РП и данных по ним', 'Изменен вид графиков во вкладких',
                    ]
                },
            ]
        },
        {
            date: '02.11.2024',
            ver: '1.0.6',
            list: [
                {
                    name: 'Экономика',
                    li: ['Обновлен API', 'Данные подключены ко всем графикам + плитки', 'Добавлена подстветка плиток', 'Исправлено отображение информации',
                        'Отображение текущего месяца на линейной и столбчатой диграммах'
                    ]
                },
                {
                    name: 'Динамика',
                    li: ['Отрисоован шариковый график']
                },
            ]
        },
        {
            date: '23.10.2024',
            ver: '1.0.5',
            list: [
                {
                    name: 'Раздел справки',
                    li: ['Вывод справки при входе в блок процентование','Возможность отключения отображения справки при входе']
                },
                {
                    name: 'Экономика',
                    li: ['Изменен раздел согласно новому макету', 'Добавлены плитки, линейная, столбчатая диаграммы', 'Вкладки по компании и РП', 'Графики: воронка, 2х-сторонний, пуля',
                        'Перенесена 2я страница экономики из старого макета'
                    ]
                },
                {
                    name: 'Графики',
                    li: ['Переход на библиотеку Highcharts','Управление легендой', 'Опции: полный экран, печать, экспорт']
                },
                {
                    name: 'Цели показатели',
                    li: ['Карточки сотрудников','Реализован предварительный макет матрицы показателей']
                },
            ]
        },
        {
            date: '6.08.2024',
            ver: '1.0.2',
            list: [
                {
                    name: 'Экономика',
                    li: ['Изменены года показа гистограм. По умолчанию установлены за 2024 и 2023 год']
                },
                {
                    name: 'Реализация / Процентование',
                    li: ['Исправлены цвета в темной теме','Новые модальные окна','Изменен дизайн справок','Обновлена справочная информация, отображение формул'
                    ]
                },
            ]
        },
        {
            date: '02.08.2024',
            ver: '1.0.1',
            list: [
                {
                    name: 'Экономика',
                    li: ['Добавлено выделение кнопок выбора месяца в гистограмме для перехода на 2ю страницу','Добавлена анимация загрузки гистограмм при изменении года просмотра']
                },
                {
                    name: 'Экономика / 2 страница',
                    li: ['Изменен вывод годов отображения с +1 и -1 года на +3 и -3 соответственно','Изменен внешний вид табличной части для лучшего отображения данных']
                },
            ]
        },
        {
            date: '01.08.2024',
            ver: '1.0.0',
            list: [
                {
                    name: 'Версионирование',
                    li: ['Добавлена страница с иторией версий и изменений в приложении']
                },
            ]
        },


    ]
    return (
        <Scroll>
            <div className='versionPage'>
                {data.map((i, index) => {
                        return (<VersionItem data={i} key={index} />)
                        }
                    )
                }
            </div>
        </Scroll>
    );
};

export default VersionLog;