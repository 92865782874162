import '../proc.scss'
import {GFormControl, GInputLabel, GTextField} from "../../../../elements/CustomMui/customMui";
import {useTheme} from "../../../../hook/useTheme";
import {
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {holdingSelectImg} from "../js/holdingSelectImg";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useModal} from "../../../../hook/useModal";
import Switch from "@mui/material/Switch";

const ProcFilters = ({allData, realization, setAllData, setWeekMonth}) => {
    const {setModal} = useModal()
    const [holding, setHolding] = useState('Все')
    const [holdingList, setHoldingList] = useState([])
    const [zakazchik, setZakazchik] = useState('All')
    const [zakazchikList, setZakazchikList] = useState([])
    const [amg, setAmg] = useState('All')
    const [amgList, setAmgList] = useState([])

    /*отображение кол-ва обьектов*/
    const [amount, setAmount] = useState(0)

    const [checked, setChecked] = useState(true)
    const switchChange = ()=>{
        setChecked(!checked)
        setWeekMonth(checked)
    }

    useEffect(()=>{
        setAmount(count(allData))
        setHoldingList(prepareSelect(realization,'Холдинг'))
        setZakazchikList(prepareSelect(realization,'Контрагент'))
        setAmgList(prepareSelect(realization,'АМГ'))

    },[realization, allData])

    const count = (data)=>{
        return data.length
    }

    const prepareSelect = (data, param) =>{
        let x = []
        data.forEach(i => {
            if (i['Холдинг'] === ''){i['Холдинг'] = 'ПРОЧИЕ'}
            if (!x.includes(i[param])){
                x.push(i[param])
            }
        } )
        return x.sort()
    }


    /*ф-я селекта холдинга*/
    const handleChangeHolding = (e)=>{
        const item = e.target.value
        setHolding(item);
        setZakazchik('All')
        setAmg('All')
        if (item === 'Все'){
            setAllData(realization)
        } else {
            setAllData(realization.filter(i => i['Холдинг'] === item))
        }
    }

    //ф-я селекта заказчика
    const handleChangeZakazchik = (e)=>{
        const item = e.target.value
        //setAllData(realization.filter(i => i['Контрагент'] === item))
        if (item === 'Все'){
            setAllData(realization)
        } else {
            setAllData(realization.filter(i => i['Контрагент'] === item))
        }
        setZakazchik(item);
        setHolding('All');
        setAmg('All');
    }
    //ф-я селекта AMG
    const handleChangeAmg = (e)=>{
        const item = e.target.value
        //setAllData(realization.filter(i => i['АМГ'] === item))
        if (item === 'Все'){
            setAllData(realization)
        } else {
            setAllData(realization.filter(i => i['АМГ'] === item))
        }
        setAmg(item);
        setZakazchik('All');
        setHolding('All');
    }

    /*Поиск*/
    const [search, setSearch] = useState('')
    /*Очистка поля поиска*/
    const resetSearch = ()=> {
        setSearch('')
        setAllData(realization)
        setHolding("Все")
        setZakazchik('All');
        setAmg('All');
    }
    /*Обновление поля поиска*/
    const handleSearch = (e) =>{
        e.preventDefault()
        setSearch(e.target.value)
    }
    /*ф-я поиска*/
    const handleKeyDown = (e)=>{
        if (e.key === 'Enter' && search.length > 2) {
            const searchedData = allData.filter(i => {
                return i['Объект'].toLowerCase().includes(search.toLowerCase())
                    || i['КодОбъекта'].includes(search)
                    || i['Контрагент'].toLowerCase().includes(search.toLowerCase())
                    || i['АМГ'].toLowerCase().includes(search.toLowerCase())
                    || i['РуководительПроекта'].toLowerCase().includes(search.toLowerCase())
            })
            setAllData(searchedData)
        }
    }

    return (
        <div className='procFilters'>
            <div style={{display: 'flex', alignItems: 'end'}}>
                <GFormControl sx={{width: 250,mr: '15px', borderBottom: '0.1rem solid #ffffff4a;'}} variant="standard" >
                    <GInputLabel sx={{color: useTheme('text')}}>Холдинг</GInputLabel>
                    <Select
                        labelId="holding-label"
                        id="holding"
                        value={holding}
                        defaultValue='Все'
                        onChange={handleChangeHolding}
                        sx={{color: useTheme('text'),width: 250, height: 32, display: 'inline-flex'}}
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: useTheme('select'),
                                        color: useTheme('text')
                                    }
                                }
                            }
                        }}
                    >
                        <MenuItem value={'Все'}>
                            <Typography variant="body1" component='span' sx={{verticalAlign: 'super'}}><b>Все холдинги</b></Typography>
                        </MenuItem>
                        {
                            holdingList.map((item, i) => {
                                let img = holdingSelectImg(item)
                                return <MenuItem key={i} value={item}>
                                    <img style={{width: '35px', paddingRight: '15px', verticalAlign: 'bottom'}} src={img} alt={item} />
                                    <Typography variant="body1" component='span' sx={{verticalAlign: 'super'}}>{item}</Typography>
                                </MenuItem>
                            })
                        }
                        <MenuItem value={'All'}></MenuItem>
                    </Select>
                </GFormControl>
                <GFormControl sx={{width: 250,mr: '15px', borderBottom: '0.1rem solid #ffffff4a;'}} variant="standard">
                    <GInputLabel id="zakazchik-label" sx={{color: useTheme('text')}}>Заказчик</GInputLabel>
                    <Select
                        labelId="zakazchik-label"
                        id="zakazchik"
                        value={zakazchik}
                        defaultValue='Все'
                        onChange={handleChangeZakazchik}
                        sx={{color: useTheme('text')}}
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: useTheme('select'),
                                        color: useTheme('text')
                                    }
                                }
                            }
                        }}
                        //disabled={zakazchikList.length <2}
                        //disabled={holding !== 'Все'}
                    >
                        <MenuItem  value={'Все'}><b>Все заказчики</b></MenuItem>
                        {
                            zakazchikList.map((item, i) => {
                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                            })
                        }
                        <MenuItem value={'All'}></MenuItem>
                    </Select>
                </GFormControl>
                <GFormControl sx={{width: 250,mr: '15px', borderBottom: '0.1rem solid #ffffff4a;'}} variant="standard">
                    <GInputLabel id="amg-label" sx={{color: useTheme('text')}}>АМГ</GInputLabel>
                    <Select
                        labelId="amg-label"
                        id="amg"
                        value={amg}
                        defaultValue='Все'
                        onChange={handleChangeAmg}
                        sx={{color: useTheme('text')}}
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: useTheme('select'),
                                        color: useTheme('text')
                                    }
                                }
                            }
                        }}
                    >
                        <MenuItem  value={'Все'}><b>Все АМГ</b></MenuItem>
                        {
                            amgList.map((item, i) => {
                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                            })
                        }
                        <MenuItem value={'All'}></MenuItem>
                    </Select>
                </GFormControl>
                <Tooltip  title={<Typography variant="body2"  gutterBottom>Переключение динамики. Месяц левее, Неделя правее</Typography>}>

                    <Stack direction="row" component="label" alignItems="center" justifyContent="center">
                        <div>Месяц</div>
                        <FormControlLabel sx={{marginLeft: '-5px', marginRight: '-2px'}} control={<Switch checked={checked} onChange={switchChange} color="success"/>} />
                        <div>Неделя</div>
                    </Stack>
                </Tooltip>
            </div>
            <div style={{display: 'flex', alignItems: 'end'}}>
                <div className='searchFilter'>
                    <GTextField id="proc_search" sx={{pt: '15px', width: '250px', pr: '15px', color: useTheme('text')}}  variant="standard" placeholder='Поиск по всем' value={search}
                                onKeyDown={handleKeyDown}  onChange={handleSearch} InputProps={{
                        startAdornment: (<InputAdornment position="start"><SearchIcon sx={{color: useTheme('text')}} /></InputAdornment>),
                        endAdornment:(<InputAdornment position="end"><IconButton onClick={resetSearch}><CloseIcon sx={{color: useTheme('text')}} /></IconButton ></InputAdornment>)
                    }}/>
                    <div className='objects' style={{color: useTheme('text'), zIndex: 1000}}>Объектов: {amount}</div>
                </div>
                <div>
                    <Tooltip title={<Typography variant="body2"  gutterBottom>Справка по блоку процентования</Typography>}>
                        <IconButton onClick={()=>setModal('ModalRealizProcWiki')} sx={{color: '#808080bf'}}><HelpOutlineIcon/></IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default ProcFilters;

