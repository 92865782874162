import React from 'react';
import {useTheme} from "../../../../../hook/useTheme";

const ModalEcoFunnelWiki = () => {
    const neonGreen = useTheme('neonGreen')

    return (
        <div className='wikiBlock'>
            <h2 className='wikiTitle' style={{color: neonGreen}}>Годовой «Маржа»</h2>
            <p>
                На графике <b style={{color: neonGreen}}>"Воронка"</b> отображены ключевые этапы реализации проектов, начиная с продажи ТКП
                (технико-коммерческих предложений) и заканчивая расчетом маржинальной прибыли.
            </p>
            <p><b>Показатели формируются по следующим принципам:</b></p>
            <ul style={{listStyle: 'square'}}>
                <li>
                    <div>1.Продано ТКП (отбор)</div>
                    <ul>
                        <li>объект не находится в архиве на дату формирования</li>
                        <li>вид работ не является гарантийным</li>
                        <li>есть оборот по СС или экономике, либо срок действия договора совпадает с периодом, либо дата подтверждения (согласования) попадает в период</li>
                        <li>продажа состоялась (указана дата подтверждения выигрыша/проигрыша)</li>
                        <li>статус "Получили соглашение – выиграли"</li>
                    </ul>
                </li>
                <li>
                    <div>2.Подписано смет/договоров:</div>
                    <ul>
                        <li>нарастающий итог по объектам, проданным в рамках ТКП, на конец периода</li>
                    </ul>
                </li>
                <li>
                    <div>3-6. Поставлено ОиМ (оборудование и материалы), оплачено ФОТ, смонтировано, запроцентовано:</div>
                    <ul>
                        <li>оперативное движение (0+1+2) по объектам отбора</li>
                    </ul>
                </li>
                <li>
                    <div>7.Маржинальная прибыль:</div>
                    <ul>
                        <li>рассчитывается только по СС текущего периода с накоплением, берется конечный остаток</li>
                    </ul>
                </li>
            </ul>
            <p><b>Особенности графика:</b></p>
            <ul>
                <li>воронка позволяет отследить эффективность каждого этапа реализации проектов</li>
                <li>маржинальная прибыль рассчитывается только для текущего периода, что обеспечивает актуальность данных</li>
            </ul>
        </div>
    );
};

export default ModalEcoFunnelWiki;