import React, {useState} from 'react';
import '../wiki.scss'
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import {useTheme} from "../../../../../hook/useTheme";

const ModalEcoLineChartWiki = () => {
    const neonGreen = useTheme('neonGreen')

    return (
        <div className='wikiBlock'>
            <h2 className='wikiTitle' style={{color: neonGreen}}>Годовой «Линейный график» с нарастающим итогом</h2>
            <p>
                Это <b style={{color: neonGreen}}>Линейный график</b>, на котором вы можете увидеть ключевые финансовые и операционные показатели,
                отображенные в двух измерениях: оборот текущего периода и нарастающий итог за год. Каждый показатель выделен отдельным цветом для наглядности.
            </p>
            <p>
                График позволяет одновременно анализировать текущую динамику показателей и их кумулятивное изменение за год (нарастающий итог).
                Это помогает оценить вклад каждого показателя в общий финансовый результат, выявить сезонные или периодические тенденции,
                а также контролировать выполнение плановых значений.
            </p>
        </div>
    );
};

export default ModalEcoLineChartWiki;