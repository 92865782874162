import {useQuery} from "react-query";
import axios from "axios";
//import {BACK} from "../utils/links";

const BACK = process.env.REACT_APP_API_URL
const getToken = () => localStorage.getItem('token') || null;

/*Realization block*/
async function fetchRealizationData(){
    const token = getToken();
    return (await axios.get(`${BACK}/api/iboardData`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    })).data
}

export const useGetRealizationData = () => {
    return useQuery('realization', fetchRealizationData,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        })
}