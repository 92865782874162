import React from 'react';
import {useTheme} from "../../../../../hook/useTheme";

const ModalEco2ChartsWiki = () => {
    const neonGreen = useTheme('neonGreen')

    return (
        <div className='wikiBlock'>
            <h2 className='wikiTitle' style={{color: neonGreen}}>Вложения</h2>
            <p>
                График <b style={{color: neonGreen}}>"Вложения"</b> отражает структуру и динамику незавершенного производства (НЗП) нарастающим итогом.
            </p>
            <p><b>Вложения включают в себя следующие компоненты:</b></p>
            <ul>
                <li>Подтверждено ОиМ (оборудование и материалы): Уровень 0 по подтвержденным счетам (конечный остаток)</li>
                <li>Поступило ОиМ: Уровень 1 и 2 (конечный остаток)</li>
                <li>Списано: Списание - это оперативный уровень(0+1+2) минус списано в СС (остаток ОиМ не списанный в СС).</li>
                <li>Начислено ФОТ (остаток в НЗП): Фонд оплаты труда, не списанный в СС</li>
                <li>Вложено на субчиков, в спецработы, спецмеханизмы: Остаток НЗП, не списанный в СС</li>
                <li>Задолженность заказчиков (Дт 62): Отражается по оборотам, отдельно учитываются авансы и поступления денежных средств</li>
            </ul>
            <p><b>Особенности графика:</b></p>
            <ul>
                <li>Позволяет отследить структуру НЗП и динамику вложений по каждому компоненту</li>
                <li>Учитывает изменения в учете ОиМ с октября 2024 года (переход на учет только 1 и 2 уровня в СС)</li>
                <li>Выделяет ключевые статьи затрат, такие как ФОТ, спецработы, спецмеханизмы и задолженность заказчиков</li>
                <li>График помогает анализировать эффективность использования ресурсов и контролировать финансовые обязательства</li>
            </ul>
         </div>
    );
};

export default ModalEco2ChartsWiki;